import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex);
//console.log("nav",JSON.stringify(nav));
export default new Vuex.Store({
  plugins: [createPersistedState()],
  state:{
    count:2,
    user:{}, // token:null,role
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    showHeader:true,
    pageTitle:"",
    memo:"",
  },
  getters:{
    doubleCount:state => state.count * 2,
    trippleCount:state => state.count * 2,
    sidebarShow:state => state.sidebarShow,
    sidebarMinimize:state => state.sidebarMinimize,
    showHeader:state => state.showHeader,
    pageTitle:state => state.pageTitle
  }
  ,mutations:{
    toggleSidebarDesktop (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
      console.log("set==============================",variable,value);
      state[variable] = value
    },
    increment(state,number){
      state.count += number;
    },
    //非同期できない
    setUser(state,user){
      console.log("setUser(state,user)",user);
      state.user = user;
    },setMemo(state,memo){
      state.memo = memo;
    },toggleHeader(state,value){
      state.showHeader = !state.showHeader;
    },
    decrement(state,number){
      state.count -= number;
    }, setNavMode(state, mode) {
      console.log("setNavMode",state,mode);
      state.nav[0]._children.splice(1, 1);
    }, setPageTitle(state, title) {
      state.title = title;
    }
  },actions:{
    //ここでは非同期可能
    //dispatch
    increment(context,number){
      // mutation呼び出し
      context.commit("increment", number);
    },setNavMode(context,mode){

      context.commit("setNavMode", mode);

    }
  }
});


