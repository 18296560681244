import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router/router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'

import axios from 'axios';
import VueAxios from 'vue-axios'
import VueSwal from 'vue-swal';
import {ToggleButton} from 'vue-js-toggle-button';
import VueDayjs from 'vue-dayjs-plugin'
import VueProgressBar from 'vue-progressbar' //https://www.kabanoki.net/3867/ progressbar参考ページ
import VueApexCharts from 'vue-apexcharts'

// import VueGoodTablePlugin from 'vue-good-table';

// import Vuelidate from 'vuelidate'
// Vue.use(Vuelidate)
Vue.use(VueAxios, axios)

// import the styles
// import 'vue-good-table/dist/vue-good-table.css'

import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";


Vue.component("v-select", vSelect);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);


import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)

// import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
// import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

//

// import * as wjcCore from "@grapecity/wijmo";
// wjcCore.setLicenseKey('116.80.41.90|localhost,467271297792437#B0nyiHZisnOiwmbBJye0ICRiwiI34zdKdFejNkZB9WVZ5GZFlFZlVEOYdVZvo7Y9gGbC3icxp4ZzlWYNhje8oWTltkWC9ET0xmUXJGaCdEdRJFMPx4TJZ5anxGaHR7QkRkcRNFRNN5MNd5SjNnRz2iVOtEcJJmQ4hFZXd6VV56Y8dVYDhVZutkaZpWN8IlcOV6Q5MlMvBneDtSUDFnWpVUeyV7NlNTZrB7NvEmaTp6VhBVYaBDR6tkeNRXZXtGSy5EWEVjYxtCUQZHTol6VGd5UkhVOydkUqZGZ7YUNNJnYzAnd6kVZ7hjd5JER6p7NndDS5YDOwFnZtVVYwZkWul4cVlHZElWaw44bCZGOzNUauhHaBRkUidEaTJzYjplSTVWQOhzVIVzb5ZWOENEbwEWdkJ5Yr8kSp9GRZ3ycZhDTwgXbvZ7aNNWV5FmVDhzL9RkSa3UezMTcWV5ZkZDcSVHR8InT4M6bxYXY9EmI0IyUiwiI4MzQzcTOzUjI0ICSiwyN7cDMzgTO4QTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI8MTOxcDMgMDMxATMyAjMiojI4J7QiwiI4N7boxWYj3GbsATOuEDNuADOuYTMxIiOiMXbEJCLi2GdvJHc+S09ayL9Pyb9qCq9iojIh94QiwiI7MDNykzN7kjMxcjM7YDNiojIklkIs4nIyYHMyAjMiojIyVmdiwSZzxWPmyj');

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(VueSwal);
Vue.use(VueDayjs);
// Vue.use(VueGoodTablePlugin);

Vue.use(VueProgressBar, {
    color: '#3ea8f8',
    failedColor: '#ea1313',
    thickness: '8px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    inverse: false,
    autoFinish: true
});

Vue.component('ToggleButton', ToggleButton);
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL;
Vue.prototype.$log = console.log.bind(console)

import BeeGridTable from "beegridtable";

import "beegridtable/dist/styles/beegridtable.css";
import BeeEn from "beegridtable/src/locale/lang/en-US";
import BeeJP from "beegridtable/src/locale/lang/ja-JP";

Vue.use(BeeGridTable, {
    capture: true,
    transfer: true,
    zIndex: 2000,   //set z-index of beegridtable
});
// import VueI18n from "vue-i18n";
// Vue.use(VueI18n);
// Vue.locale = () => {};

// import jp from "locale/lang/ja-JP";
// import us from "./locale/lang/en-US";

// const messages = {
//     en: Object.assign(us, BeeEn),
//     jp: Object.assign(cn, BeeJP),
// };
// const i18n = new VueI18n({
//     locale: localStorage.getItem("language") || "en",
//     messages: messages,
// });

new Vue({
    el: '#app',
    router,
    store,
    icons,
 //   i18n,
 //    template: '<App/>',
 //    components: {
 //        App
 //    },
    render(createElement){
        return createElement(App)
    },mounted() {
        console.log(`process.env.VUE_SERVER_URL =  ${process.env.VUE_APP_SERVER_URL}`)
        axios.interceptors.request.use(
            config => {
                this.$Progress.start();
                // console.log('interceptoers request ${this.$store.state.user.token}', config);
                if (this.$store.state.user.token) {
                    config.headers['token'] = this.$store.state.user.token;
                }
                return config;
            },
            error => {
                this.$Progress.fail();
                //もう一回サーバーにリクエストを投げることができる axios.get()
                //return error;//thenつながる
                return Promise.reject(error);//呼び出し元のcatchにつながる
            }
        );
        axios.interceptors.response.use(
            response => {
                this.$Progress.finish();
                // console.log('interceptoers response', response);
                if (response.data && response.data.message != undefined && response.data.message != "" ) {
                    this.$swal(response.data.message);
                }
                return response;
            },
            error => {
                this.$Progress.fail();
                //もう一回サーバーにリクエストを投げることができる axios.get()
                //return error;//thenつながる
                this.$swal(error.response.data.message).then( function() {
                    if(error.response.data.message.indexOf("ログイン情報が取得できません。") == 0){
                        window.location = "/";
                        return;
                    }
                });
                //console.log("error",error.response.data.message);
                return Promise.reject(error);//呼び出し元のcatchにつながる
            }
        );

        this.$router.beforeEach((to, from, next) => {
            // console.log('global: beforeEach `${from} ${to}`',this.$options.router);
            // console.log(this.$store.state.count);
            if (to.meta.progress !== undefined) {
                let meta = to.meta.progress
                this.$Progress.parseMeta(meta)
            }
            this.$Progress.start()
            if(to.meta){
                this.$store.state.pageTitle = to.meta.title;
            }else{
                this.$store.state.pageTitle = "";
            }
            next()
        });


        this.$router.afterEach((to, from) => {
            // console.log("this.$Progress.finish()");
            this.$Progress.finish()
        });
    }
})
